<!-- 招生系统基础配置 -- 中考考试科目管理 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(()=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<h4 style="padding: 0 0 20rem 30rem">中考区域</h4>
		<el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
			<el-form-item label="省份" prop="province">
				<el-select v-model="form.province" placeholder="请选择省份" @change="getCity">
					<el-option v-for="item in provinceOptions" :label="item.address_name" :value="item.address_code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="城市" prop="city">
				<el-select v-model="form.city" placeholder="请选择城市" @change="getArea">
					<el-option v-for="item in cityOptions" :label="item.address_name" :value="item.address_code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="区/县" prop="area">
				<el-select v-model="form.area" placeholder="请选择区/县" @change="Clear">
					<el-option v-for="item in areaOptions" :label="item.address_name" :value="item.address_code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="考试年份" prop="year">
				<el-select v-model="form.year" placeholder="请选择考试年份" @change="Clear">
					<el-option :label="(new Date().getFullYear() - 2).toString()" :value="(new Date().getFullYear() - 2).toString()"></el-option>
					<el-option :label="(new Date().getFullYear() - 1).toString()" :value="(new Date().getFullYear() - 1).toString()"></el-option>
					<el-option :label="new Date().getFullYear().toString()" :value="new Date().getFullYear().toString()"></el-option>
					<el-option :label="(new Date().getFullYear() + 1).toString()" :value="(new Date().getFullYear() + 1).toString()"></el-option>
					<el-option :label="(new Date().getFullYear() + 2).toString()" :value="(new Date().getFullYear() + 2).toString()"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="!hint" style="color: red">
				当前城市没有配置,请重新选择
			</el-form-item>
			<div v-if="examArea.length">
				<el-form-item label="计分方式">
					{{ examArea[0].score_type_text }}
				</el-form-item>
				<el-form-item label="总分">
					{{ examArea[0].total_score }}
				</el-form-item>
				<el-form-item label="普高线">
					{{ examArea[0].score_line }}
				</el-form-item>
				<el-form-item v-if="examArea[0].freedom_line " label="自主划线分数">
					{{ examArea[0].freedom_line }}
				</el-form-item>
			</div>

			<h4 v-show="examArea.length" style="padding: 50rem 0 0 30rem">考试区域</h4>
		</el-form>
		<el-form label-position="top" style="text-align: center;margin-bottom: 24rem;padding: 0 24rem">
			<div v-for="(item, index) in submitData.examination_rank" v-if="examArea.length" :key="index" class="knowledge">
				<div class="flex" style="background: #f4f4f4;padding: 20rem 10rem;align-items: center">
					<el-form-item label="档位" label-width="40rem" style="margin-right: 12rem">
						<el-select v-model="item.rank_name" :popper-append-to-body="true" @change="$forceUpdate()">
							<el-option v-for="item in gearArr" :label="item.label" :value="item.value">
						</el-option></el-select>
					</el-form-item>
					<el-form-item v-if="examArea[0].score_type === 1 || examArea[0].score_type === 2" label="等级" label-width="120rem" style="margin-right: 12rem">
						<el-input v-model="item.rank_level" maxlength="10" placeholder="请输入" @input="$forceUpdate()"></el-input>
					</el-form-item>
					<el-form-item v-if="examArea[0].score_type === 2" label="最低分数" label-width="120rem" style="margin-right: 12rem">
						<el-input v-model="item.rank_score" maxlength="10" placeholder="请输入" @input="$forceUpdate()"></el-input>
					</el-form-item>
					<el-form-item v-if="examArea[0].score_type === 0 || examArea[0].score_type === 3" label="最低分数大于" label-width="120rem" style="margin-right: 12rem">
						<el-input v-model="item.rank_score_min" maxlength="10" placeholder="请输入" @input="$forceUpdate()"></el-input>
					</el-form-item>
					<el-form-item v-if="examArea[0].score_type === 0 || examArea[0].score_type === 3" label="最高分数小于等于" label-width="160rem" style="margin-right: 12rem">
						<el-input v-model="item.rank_score_max" maxlength="10" placeholder="请输入" @input="$forceUpdate()"></el-input>
					</el-form-item>
					<el-form-item v-if="form.city === '512000'" label="对应等级" label-width="80rem" style="margin-right: 12rem">
						<el-input v-model="item.rank_level" maxlength="10" placeholder="请输入" @input="$forceUpdate()"></el-input>
					</el-form-item>
				</div>
				<div class="flex" style="margin-left: 24rem">
					<el-button v-if="submitData.examination_rank.length !== 1" circle="" icon="el-icon-delete" @click="deleteKnowledge(index)"></el-button>
					<el-button circle="" icon="el-icon-plus" @click="addKnowledge"></el-button>
				</div>
			</div>
		</el-form>
		
	
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			backType: 0,
			form: {
				province: '',
				city: '',
				area: '',
				year: '',
			},
			hint: 1,
			rules: {
				province: [{required: true, message: "请选择省份"}],
				city: [{required: true, message: "请选择城市"}],
				area: [{required: true, message: "请选择区/县"}],
				year: [{required: true, message: "请选择年份"}],
			},
			provinceOptions: [],
			cityOptions: [],
			areaOptions: [],
			subjectOptions: [],
			examArea: [],
			gearArr: [], // 档位数据
			submitData: {
				examination_id: '',
				examination_rank: [
					{
						rank_name: "",
						rank_level: "",
						rank_score: "",
						rank_score_min: "",
						rank_score_max: ""
					},
				]
			}, // 提交数据
		}
	},
	created() {
		this.getProvince()
		this.getSubject()
		this.gearArr = []
		this.$_register.get('api/recruit/examination-rank/get-rank').then(res => {
			res.data.data.forEach(item => {
				this.gearArr.push({
					label: item,
					value: item,
					disabled: false
				})
			})
		})
	},
	watch: {
		form: {
			deep: true,
			handler(val) {
				for (let valKey in val) {
					if (!val[valKey]) return
				}
				this.$_register.get('api/recruit/exam-conf/search', {params: val}).then(res => {
					this.examArea = res.data.data
					this.hint = res.data.data.length
					this.submitData.examination_id = res.data.data[0].id
					this.submitData.examination_rank = [{
						rank_name: "",
						rank_level: "",
						rank_score: "",
						rank_score_min: "",
						rank_score_max: ""
					}]
				})
			}
		},
		submitData: {
			deep: true,
			handler(val, old) {
				let arr = (val.examination_rank || []).map(item => item.rank_name)
				this.gearArr.forEach(item => {
					item.disabled = arr.includes(item.value)
				})
			}
		}
	},
	methods: {
		getSubject() {
			this.$_register('/api/recruit/examination/subject').then(res => {
				let data = res.data.data
				if (data.length) {
					this.subjectOptions = data.map(item => {
						return {
							...item,
							disabled: false
						}
					})
				}
			})
		},
		Clear() {
			this.examArea = []
			this.submitData = {
				examination_id: '',
				examination_rank: [
					{
						rank_name: "",
						rank_level: "",
						rank_score: "",
						rank_score_min: "",
						rank_score_max: ""
					},
				]
			}
		},
		// 添加科目
		addKnowledge() {
			this.submitData.examination_rank.push({
				rank_name: "",
				rank_level: "",
				rank_score: "",
				rank_score_min: "",
				rank_score_max: "",
			})
			this.$forceUpdate()
		},

		//获取省份
		getProvince() {
			this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
				// console.log(res)
				this.provinceOptions = res.data.data
			})
		},

		//获取城市
		getCity(e) {
			this.Clear()
			this.cityOptions = []
			this.form.city = ''
			this.areaOptions = []
			this.form.area = ''
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.cityOptions = res.data.data
			})
		},

		//获取区/县
		getArea(e) {
			this.Clear()
			this.areaOptions = []
			this.form.area = ''
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.areaOptions = res.data.data
			})
		},

		// 删除科目
		deleteKnowledge(index) {
			this.submitData.examination_rank.splice(index, 1)
			this.$forceUpdate()
		},

		// 提交数据
		submit() {
			this.$refs.form.validate((res) => {
				if (res) {
					this.$_register.post("/api/recruit/examination-rank/add", this.submitData).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg,
							onClose: () => {
								this.$store.commit("setPage", 1);
								this.backType = 1
								this.$router.back();
							}
						});
					})
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>

.form {
	padding-top: 24rem;
}

//::v-deep .el-form .el-input {
//	min-width: 250rem;
//}

.knowledge {
	margin-top: 24rem;
	display: flex;
	align-items: center;

	.knowledge_box {
		display: flex;
		padding-top: 12rem;
		height: 60rem;
		background-color: #f4f4f4;
	}

	.add_knowledge {
		display: flex;
	}
}

@media only screen and (max-width: 1399rem) {
	::v-deep .el-input {
		width: 180rem !important;
	}
}
</style>
